import styled from 'styled-components'

export const DocsBodyWrapper = styled.section`
  padding: 3rem 0;
  h1,
  h2,
  h3,
  h4 {
    padding: 3rem 0 1rem;
  }
  p {
    padding: 0.2rem 0;
  }

  ul,
  ol {
    padding-left: 2rem;
  }
`
