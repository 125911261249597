import React from 'react'
import { TertiaryHeaderWrapper } from './tertiary-header-styles'

function TertiaryHeader({ title, subtitle }) {
  return (
    <TertiaryHeaderWrapper>
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </TertiaryHeaderWrapper>
  )
}

export default TertiaryHeader
