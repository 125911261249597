import styled from 'styled-components'
import gear from '../../../images/illustrations/gears.svg'
import gear2 from '../../../images/illustrations/gears2.svg'
import media from '../../utils/breakpoints'

export const TertiaryHeaderWrapper = styled.header`
  padding: 2rem 1rem;
  text-align: center;
  background: url(${gear}) 2rem center / 8rem no-repeat,
    url(${gear2}) right center / 8rem no-repeat;
  h1 {
    padding-bottom: 0.5rem;
  }

  ${media.md} {
    background-size: contain;
  }
`
