import React from 'react'
import { graphql } from 'gatsby'
import TertiaryHeader from '../../components/header/tertiary/teriary-header'
import { Container } from '../../components/common/common-styles'
import { DocsBodyWrapper } from './docs-styles'
import SEO from '../../components/seo/seo'

function Docs({ data }) {
  const title = data.markdownRemark.frontmatter.title
  const subtitle = data.markdownRemark.frontmatter.subtitle
  return (
    <>
      <SEO title={title} />
      <TertiaryHeader title={title} subtitle={subtitle} />
      <Container>
        <DocsBodyWrapper
          className="body"
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></DocsBodyWrapper>
      </Container>
    </>
  )
}

export default Docs

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        subtitle
      }
    }
  }
`
